import { create } from "@storybook/theming/create";
import logo from "./logo-pede-pronto.png";

import "./styles.css";

export default create({
  base: "light",
  brandTitle: "Prontinho",
  brandUrl: "https://pedepronto.com.br",
  brandImage: logo,
  brandTarget: "_self",
  barSelectedColor: "#de4a82",
  colorSecondary: "#de4a82",
  barBg: "#f2f2f2",
  appBg: "#f2f2f2",
  barTextColor: "#333",
  textColor: "#333",
});
